body {
    height: 100vh;
    background: #f0f2f5;
}

h3 {
    margin: 0;
}

.button {
    cursor: pointer;
}

.active {
    padding-bottom: 10px;
    border-bottom: 3px solid #ffffff;
}

.accordion {
    border-top: 1px solid #919191;
    margin-top: 20px;
    width: 100%;
}

.accordion-item {
    background: #f0f2f5;
    border-bottom: 1px solid #919191;
    padding-top: 15px;
}

.accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin: 0 20px;
}

.content {
    font-weight: bold;
    background: #ffffff;
    padding: 15px 10px 15px 50px;
}

.colored {
    background: #e7e7e7 !important;
}

.pagination {
    padding-top: 10px;
}

.openButton {
    padding: 4px 9px;
    font-weight: bold;
}

.editButton {
    padding: 2px 4px;
}
