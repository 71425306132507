aside {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background-color: #fbfbfb;
  height: 100vh;
  width: 340px;
  box-shadow: 0px 1px 1px 0 rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

aside a {
  color: #fff;
}

aside > .menu {
  display: flex;
  flex-direction: column;
}

.logo,
.aside-footer > * > img {
  width: calc(340px - 24px);
  margin: 12px;
}

.aside-footer {
  margin-top: auto;
}
